<template>
  <section class="rims-config-tpms-kit-design-details">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @on-click-brand="toTpmsKit"
      @on-click-tpmsServiceKits="toTpmsKitOverview"
    />
    <v-container>
      <div class="tpms-kit-showroom">
        <div class="tpms-kit-options-slider">
          <swiper
            ref="tpmsKitOptionsSlider"
            class="swiper"
            :options="swiperOption"
            @slide-change="onSlideChange"
          >
            <swiper-slide
              v-for="slide in 1"
              :key="slide"
            >
              <div class="corners">
                <icon-corner
                  v-for="(item, index) in slideActiveCorners"
                  :key="`${item}${index}`"
                  :class="item"
                />
              </div>
              <v-card
                rounded="lg"
                @click="onClickSlide"
              >
                <img
                  v-if="!empty(getTpmsKitItem) && getTpmsKitItem.imageTemplate"
                  :src="getTpmsKitImage(getTpmsKitItem.imageTemplate)"
                >
              </v-card>
            </swiper-slide>
          </swiper>
          <div
            class="custom-swiper-button-prev"
            @click="onSlidePrev"
          >
            <icon-arrow-circle />
          </div>
          <div
            class="custom-swiper-button-next"
            @click="onSlideNext"
          >
            <icon-arrow-circle />
          </div>
        </div>
        <div
          class="tpms-kit-image-zoom"
        >
          <inner-image-zoom
            v-if="!empty(getTpmsKitItem) && getTpmsKitItem.imageTemplate"
            :src="getTpmsKitImage(getTpmsKitItem.imageTemplate)"
            :height="326"
            :width="326"
            :zoom-scale="2.5"
            zoom-type="hover"
          />
        </div>
        <v-card
          class="tpms-kit-description"
          rounded="lg"
        >
          <v-card-title class="card__title">
            <img
              v-if="!empty(getTpmsKitItem) && getTpmsKitItem.manufacturer"
              :src="`https://cdn.jfnet.de/tpmsImg/${getTpmsKitItem.manufacturer.replace(/\s/g, '').toUpperCase()}/200x80-cc-xxxxxx.png`"
              class="brand-image"
            >
          </v-card-title>
          <v-card-text class="card__content">
            <h6 class="design">
              {{ getTpmsKitMan }}
            </h6>
            <p
              v-if="!empty(getTpmsKitItem)"
              class="description"
            >
              {{ `${getTpmsKitItem.description1} ${getTpmsKitItem.description2}` }}<br>
              {{ $t('langkey.type') }}: {{ getTpmsKitItem.type }}<br>
              EAN: {{ getTpmsKitItem.ean }}<br>
              {{ $t('langkey.manufacturer_article_number') }}: {{ getTpmsKitItem.man }}
            </p>
          </v-card-text>
        </v-card>
      </div>
      <v-data-table
        class="primary-style"
        :headers="tableFieldTpmsKit"
        :items="getTpmsKitsTableItems"
        :loading="isLoadingTpmsKit"
        hide-default-footer
      >
        <template
          v-for="(field, index) in tableFieldTpmsKit"
          v-slot:[`header.${field.value}`]="{ header }"
        >
          <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
        </template>
        <template v-slot:[`item.purchasePrice`]="{ item }">
          {{ item.prices.bestPrice.purchasePrice | price }}
        </template>
        <template v-slot:[`item.retailPrice`]="{ item }">
          {{ item.prices.bestPrice.retailPrice | price }}
        </template>
        <template v-slot:[`item.retailTaxPrice`]="{ item }">
          {{ item.prices.bestPrice.retailTaxPrice | price }}
        </template>
        <template v-slot:[`item.quantity`]="{item}">
          <div class="qty__wrapper">
            <v-btn
              class="qty-minus"
              icon
              plain
              @click="onSubtractQuantity(`${item.assign}`)"
            >
              <icon-subtract />
            </v-btn>
            <input
              v-model.number="selectQuantity[`${item.assign}`]"
              class="qty__input"
              @change="onUpdateQuantity($event, `${item.assign}`)"
            >
            <v-btn
              class="qty-plus"
              icon
              plain
              @click="onAddQuantity(`${item.assign}`)"
            >
              <icon-add />
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span v-if="!empty(tpmsKitAssignByMan) && (priceType === 'retailPrice' || priceType === 'retailTaxPrice')">
            {{ selectQuantity[`${item.assign}`] * item.prices.bestPrice.retailTaxPrice | price }}
          </span>
          <span v-if="!empty(tpmsKitAssignByMan) && (priceType === 'purchasePrice')">
            {{ selectQuantity[`${item.assign}`] * item.prices.bestPrice.purchasePrice | price }}
          </span>
        </template>
        <template v-slot:[`item.function`]="{ item }">
          <v-btn
            icon
            plain
            @click="addToCart(item)"
          >
            <icon-add-to-cart />
          </v-btn>
        </template>
      </v-data-table>
    </v-container>

    <base-add-to-cart-dialog
      ref="addToCartDialog"
      is-rim-config
      :cart-items="cartItems"
      :select-quantity-table="selectQuantity"
      supplier-source
      @on-subtract-quantity="onSubtractQuantityCart"
      @on-add-quantity="onAddQuantityCart"
      @on-update-quantity="onUpdateQuantityCart"
      @confirm-and-show-cart="onConfirmAndShowCart"
      @continue-shopping="onContinueShopping"
      @supplier-add-cart="onSupplierAddToCart"
    />
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config-tpms-kit-design-details.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import IconCorner from '@/components/icons/IconCorner';
import IconArrowCircle from '@/components/icons/IconArrowCircle';
import IconAdd from '@/components/icons/IconAdd';
import IconSubtract from '@/components/icons/IconSubtract';
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import { breadcrumbItems, tableFieldTpmsKit, tableFieldTpmsKitRetail } from './configs';
import { mapState, mapGetters } from 'vuex';
import { getImageBySize } from '@/utils';
import _ from 'lodash';

export default {
  components: {
    RimconfigBreadcrumb,
    IconCorner,
    IconArrowCircle,
    IconAdd,
    IconSubtract,
    InnerImageZoom,
  },
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 3,
        spaceBetween: 8,
        centeredSlides: true,
        // loop: true,
        slideActiveClass: 'active',
        navigation: {
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev',
        },
      },
      slideActiveCorners: [
        'top-left',
        'top-right',
        'bottom-right',
        'bottom-left',
      ],
      currentSlide: 0,

      isLoadingTpmsKit: false,
      cartItems: [],
      selectQuantity: {},
    };
  },
  computed: {
    ...mapState('rimconfigTpms', [
      'tpmsKitAssignByMan',
      'tpmsKitsOnlyData',
    ]),
    ...mapState('pricing', ['priceType']),
    ...mapGetters('rimconfigTpms', ['getTpmsKitsOnlyDataBySupplier']),
    breadcrumbItems() {
      return breadcrumbItems;
    },
    tableFieldTpmsKit() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return tableFieldTpmsKitRetail;
      } else {
        return tableFieldTpmsKit;
      }
    },
    getSearch() {
      return this.$route.query.search;
    },
    getTpmsKitMan() {
      return this.$route.query.man;
    },
    getTpmsKitItem() {
      return this.tpmsKitsOnlyData.find((item) => item.man === this.getTpmsKitMan);
    },
    getTpmsKitsTableItems() {
      return this.getTpmsKitsOnlyDataBySupplier(this.getTpmsKitMan);
    },
  },
  watch: {
    getTpmsKitsTableItems(val) {
      this.setInitSelectQuantity();
    },
  },
  created() {
    // 'man' is required
    if (!this.getSearch) {
      if (!this.getTpmsKitMan) {
        this.toTpmsKitOverview();
      } else {
        if (this.tpmsKitsOnlyData.length === 0) {
          this.isLoadingTpmsKit = true;
          let payload = {
            mans: this.getTpmsKitMan,
          };
          this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly', {
            ...payload,
          })
            .then(() => {
              this.isLoadingTpmsKit = false;
              if (this.tpmsKitsOnlyData.length === 0) {
                this.$store.dispatch('dialog/setShowErrorDialog', {
                  status: true,
                  title: this.$t('langkey.no-tpms-kits-found-header'),
                  message: this.$t('langkey.no-tpms-kits-found-message'),
                });
                this.toTpmsKitOverview();
              } else {
                this.$store.dispatch('rimconfigTpms/getTpmsKitAssigns', {
                  tpmsKitMan: this.getTpmsKitMan,
                  assigns: this.getTpmsKitItem.assigns,
                });
              }
            })
            .catch(() => {
              this.isLoadingTpmsKit = false;
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.no-tpms-kits-found-header'),
                message: this.$t('langkey.no-tpms-kits-found-message'),
              });
              this.toTpmsKitOverview();
            });
        } else {
          this.setInitSelectQuantity();
          this.$store.dispatch('rimconfigTpms/getTpmsKitAssigns', {
            tpmsKitMan: this.getTpmsKitMan,
            assigns: this.getTpmsKitItem.assigns,
          });
        }
      }
    } else {
      if (!this.getTpmsKitMan) {
        this.toTpmsKitOverview();
      } else {
        if (this.tpmsKitsOnlyData.length === 0) {
          this.isLoadingTpmsKit = true;
          let payload = {
            mans: this.getTpmsKitMan,
          };
          this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly', {
            ...payload,
          })
            .then(() => {
              this.isLoadingTpmsKit = false;
              if (this.tpmsKitsOnlyData.length === 0) {
                this.$store.dispatch('dialog/setShowErrorDialog', {
                  status: true,
                  title: this.$t('langkey.no-tpms-kits-found-header'),
                  message: this.$t('langkey.no-tpms-kits-found-message'),
                });
                this.toTpmsKitOverview();
              } else {
                this.$store.dispatch('rimconfigTpms/getTpmsKitAssigns', {
                  tpmsKitMan: this.getTpmsKitMan,
                  assigns: this.getTpmsKitItem.assigns,
                });
              }
            })
            .catch(() => {
              this.isLoadingTpmsKit = false;
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.no-tpms-kits-found-header'),
                message: this.$t('langkey.no-tpms-kits-found-message'),
              });
              this.toTpmsKitOverview();
            });
        } else {
          this.setInitSelectQuantity();
          this.$store.dispatch('rimconfigTpms/getTpmsKitAssigns', {
            tpmsKitMan: this.getTpmsKitMan,
            assigns: this.getTpmsKitItem.assigns,
          });
        }
      }
    }
  },
  methods: {
    empty: _.isEmpty,
    onSlideChange(swiper) {
      this.currentSlide = swiper.realIndex;
    },
    onSlidePrev() {
      this.$refs.tpmsKitOptionsSlider.$swiper.slidePrev();
    },
    onSlideNext() {
      this.$refs.tpmsKitOptionsSlider.$swiper.slideNext();
    },
    onClickSlide() {
      let clickedIndex = this.$refs.tpmsKitOptionsSlider.$swiper.clickedIndex;
      this.$refs.tpmsKitOptionsSlider.$swiper.slideTo(clickedIndex);
    },
    toTpmsKit() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-tpmsKit',
      }));
    },
    toTpmsKitOverview() {
      if (this.getSearch) {
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-tpmsKit-overview',
          query: {
            search: this.getSearch,
          },
        }));
      } else {
        if (this.getTpmsKitMan) {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-tpmsKit-overview',
            query: {
              search: this.getTpmsKitMan,
            },
          }));
        } else {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-tpmsKit-overview',
          }));
        }
      }
    },
    getTpmsKitImage(image) {
      return getImageBySize(image, 400, 400);
    },
    setInitSelectQuantity() {
      this.getTpmsKitsTableItems.forEach((item) => {
        const itemKey = `${item.assign}`;
        this.selectQuantity = {
          ...this.selectQuantity,
          [itemKey]: 4,
        };
      });
    },
    addToCart(item) {
      let cartItems = [];
      const userType = sessionStorage.getItem('userType');
      if (
        userType &&
        userType.toString().toLowerCase().includes('test')
      ) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      cartItems.push({
        ...item,
        description: `${item.description1} ${item.description2}`,
        articleId: item.man,
        quantity: this.selectQuantity[`${item.assign}`] || 1,
        price: item.purchasePrice || 0,
        suppliers: this.tpmsKitAssignByMan[item.man],
      });
      let payload = {
        cartItems: cartItems,
        item: item,
        quantity: this.selectQuantity[item.assign],
      };
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('cart/addCartItem', {
        id: payload.item.assign,
        quantity: payload.quantity,
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        });
    },
    onSubtractQuantity(man) {
      let newQty;
      if (this.selectQuantity[man] > 1) {
        newQty = this.selectQuantity[man] - 1;
      } else {
        newQty = 1;
      }

      this.selectQuantity = {
        ...this.selectQuantity,
        [man]: newQty,
      };
    },
    onAddQuantity(assign) {
      let newQty;
      const maxStock = this.tpmsKitAssignByMan[this.getTpmsKitMan]
        .find((item) => {
          return item.id.toString() === assign.toString();
        }).stock;
      if (this.selectQuantity[assign] < maxStock) {
        newQty = this.selectQuantity[assign] + 1;
      } else {
        newQty = maxStock;
      }

      this.selectQuantity = {
        ...this.selectQuantity,
        [assign]: newQty,
      };
    },
    onUpdateQuantity(event, man) {
      let newQty = parseInt(event.target.value);
      if (!Number.isInteger(newQty) || newQty < 0) {
        newQty = 1;
      }

      this.selectQuantity = {
        ...this.selectQuantity,
        [man]: newQty,
      };
    },
    onSubtractQuantityCart(item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.assigns[0] === item.articleId);
      this.cartItems[index].quantity > 0 ? this.cartItems[index].quantity-- : 0;
    },
    onAddQuantityCart(item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.assigns[0] === item.articleId);
      this.cartItems[index].quantity++;
    },
    onUpdateQuantityCart (item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.articleId === item.articleId);
      let newQty = item.quantity;
      if (!Number.isInteger(newQty) || newQty < 0) {
        newQty = 1;
      }
      this.cartItems[index].quantity = newQty;
    },
    onConfirmAndShowCart() {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.cartItems[0];
      this.$store.dispatch('cart/addCartItem', {
        id: item.articleId,
        quantity: item.quantity,
      })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onContinueShopping() {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.cartItems[0];
      this.$store.dispatch('cart/addCartItem', {
        id: item.articleId,
        quantity: item.quantity,
      })
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onSupplierAddToCart({ item, quantity, kitHan, carDescription }) {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('cart/addCartItem', {
        id: item.id,
        quantity: quantity,
        context: {
          kitMan: kitHan,
          carDescription: carDescription,
        },
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        });
    },
  },
};
</script>