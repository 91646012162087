export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    to: 'brand',
  },
  {
    key: 'tpmsServiceKits',
    label: 'TPMS Service Kits',
    to: 'tpmsServiceKits',
  },
  {
    key: 'tpmsServiceKitsDetails',
    label: 'TPMS Service Kits Details',
  },
];

export const tableFieldTpmsKit = [
  { value: 'supplier', text: 'langkey.supplier', align: 'center' },
  { value: 'stock', text: 'langkey.stock', align: 'center' },
  { value: 'purchasePrice', text: 'langkey.price', align: 'center', sortable: false },
  { value: 'quantity', text: 'langkey.quantity', align: 'center', sortable: false },
  { value: 'total', text: 'langkey.total_price', align: 'center', sortable: false },
  { value: 'function', text: '', align: 'center', sortable: false },
];

export const tableFieldTpmsKitRetail = [
  { value: 'supplier', text: 'langkey.supplier', align: 'center' },
  { value: 'stock', text: 'langkey.stock', align: 'center' },
  { value: 'retailPrice', text: 'langkey.netto', align: 'center', sortable: false },
  { value: 'retailTaxPrice', text: 'langkey.brutto', align: 'center', sortable: false },
  { value: 'quantity', text: 'langkey.quantity', align: 'center', sortable: false },
  { value: 'total', text: 'langkey.total_price', align: 'center', sortable: false },
  { value: 'function', text: '', align: 'center', sortable: false },
];